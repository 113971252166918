import React from "react";
import MainDashboardContent from "../Components/MainDashBoardComponent/MainDashBoardContent";
import Navbar from "../Components/NavBarComponent";
import { Box } from "@mui/material";
const Dashboard = () => {
  return (
    <>
      <Navbar />
      <Box
        sx={{
          backgroundColor: "#101b23",
          minHeight: "100vh",
          padding: 1,
          paddingTop: 4,
        }}
      >
        <MainDashboardContent />
      </Box>
    </>
  );
};

export default Dashboard;
