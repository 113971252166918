import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Typography,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../contexts/GlobalContext";
import { ip } from "../../config";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import axios from "axios";
import moment from "moment-timezone";
const ExploreAudioContent = () => {
  const { token, setToken } = useContext(GlobalContext);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;
  const [filter, setFilter] = useState("all");
  const [audioData, setAudioData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const navigate = useNavigate();

  const filteredData =
    filter === "all"
      ? audioData
      : audioData.filter((audio) =>
          filter === "completed"
            ? audio.completed === true
            : audio.completed === false
        );

  const getData = async () => {
    try {
      const response = await axios.get(
        `${ip}/getAudios/${currentPage}/${rowsPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAudioData(response.data.audios);
      //console.log("Data:",response.data.audios)
    } catch (e) {
      console.error("Failed to get audios initial data");
    }
  };
  useEffect(() => {
    const check = async () => {
      if (!token) {
        navigate("/login");
      }
      try {
        await axios.get(`${ip}/validate`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        getData();
      } catch (e) {
        localStorage.removeItem("loginToken");
        setToken(null);
        navigate("/login");
      }
    };

    check();
  }, [currentPage, token, navigate, setToken]);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => clearTimeout(delayDebounce);
  }, [searchTerm]);

  useEffect(() => {
    const searchByName = async () => {
      if (debouncedSearchTerm) {
        try {
          const response = await axios.get(
            `${ip}/searchAudio/${debouncedSearchTerm}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          //console.log("Fetching search results...");
          setAudioData(response.data.audios);
        } catch (e) {
          console.error("Failed to search by name");
          if (e.status === 403) {
            localStorage.removeItem("loginToken");
            setToken(null);
            navigate("/login");
          }
        }
      } else {
        // setAudioData(null);
        getData();
      }
    };

    searchByName();
  }, [debouncedSearchTerm, token]);
  const handlePreviousPage = () =>
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  const handleNextPage = () => setCurrentPage((prev) => prev + 1);

  return (
    <Container maxWidth="lg" sx={{ padding: 1 }}>
      <Typography
        variant="h4"
        align="center"
        sx={{ fontWeight: "bold", color: "#fff", mb: 3 }}
      >
        Explore Audios!
      </Typography>

      <Grid container spacing={2} justifyContent="center">
        <RadioGroup
          row
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        >
          {["all", "completed", "pending"].map((value) => (
            <Grid item xs={5} sm={3} md={4} key={value}>
              <Box
                sx={{
                  bgcolor: filter === value ? "#e3f2fd" : "#1b2e3a",
                  borderRadius: 2,
                  padding: "9px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: "105px",
                  marginRight: 2,
                  marginTop: 2,
                  boxShadow:
                    filter === value ? "0 4px 10px rgba(0, 0, 0, 0.2)" : "none",
                  transition: "background-color 0.3s, box-shadow 0.3s",
                }}
              >
                <FormControlLabel
                  value={value}
                  control={
                    <Radio
                      sx={{
                        color: "#1976d2",
                        "&.Mui-checked": { color: "#1976d2" },
                      }}
                    />
                  }
                  label={value.charAt(0).toUpperCase() + value.slice(1)}
                  sx={{ color: filter === value ? "#1976d2" : "#ddd" }}
                />
              </Box>
            </Grid>
          ))}
        </RadioGroup>
      </Grid>

      <TextField
        variant="outlined"
        fullWidth
        placeholder="Search..."
        sx={{
          margin: "20px 0",
          "& .MuiOutlinedInput-root": {
            borderRadius: 4,
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#fff",
          },
        }}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <TableContainer
        component={Paper}
        sx={{
          borderRadius: 2,
          overflow: "auto",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
          bgcolor: "#1b2e3a",
        }}
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {["Name", "Date Added", "Status", "Action"].map((header) => (
                <TableCell
                  key={header}
                  sx={{
                    fontWeight: "bold",
                    bgcolor: "#2c3e50",
                    color: "white",
                    borderBottom: "2px solid #ccc",
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((audio, index) => (
              <TableRow key={index}>
                <TableCell sx={{ color: "white" }}>{audio.name}</TableCell>
                <TableCell sx={{ color: "white" }}>
                  {/* {moment(audio.timestamps).format('MMMM D, YYYY, HH:mm:ss')}
                   */}

                  {moment
                    .utc(audio.uploadedAt)
                    .tz("Asia/Karachi")
                    .format("DD-MM-YYYY HH:mm:ss")}
                </TableCell>
                <TableCell>
                  <div
                    style={{
                      padding: "5px 10px",
                      borderRadius: "5px",
                      cursor: "pointer",
                      backgroundColor: audio.completed ? "#24b053" : "#E4D00A",
                      color: audio.completed ? "white" : "black",
                      textAlign: "center",
                    }}
                  >
                    {audio.completed ? "Completed" : "Pending"}
                  </div>
                </TableCell>
                <TableCell>
                  <Button
                    sx={{
                      color: "#1976d2",
                      "&:hover": {
                        backgroundColor: "#e3f2fd",
                      },
                    }}
                    onClick={() =>
                      navigate("/viewdetails", {
                        state: { audioId: audio.name },
                      })
                    }
                  >
                    View Detail
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
            minWidth: 650,
            backgroundColor: "#2c3e50",
            color: "white",
          }}
        >
          <IconButton onClick={handlePreviousPage} disabled={currentPage === 1}>
            <ArrowBackIosIcon sx={{ color: "white" }} />
          </IconButton>
          <span style={{ margin: "0 10px" }}>Page {currentPage}</span>
          <IconButton
            onClick={handleNextPage}
            disabled={filteredData.length < rowsPerPage}
          >
            <ArrowForwardIosIcon sx={{ color: "white" }} />
          </IconButton>
        </div>
      </TableContainer>
    </Container>
  );
};

export default ExploreAudioContent;
