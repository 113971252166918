export const audioData = [
  {
    name: "Audio 1",
    score: 85,
    dateAdded: "2024-01-01",
    status: "Completed",
  },
  { name: "Audio 2", score: 75, dateAdded: "2024-01-05", status: "Pending" },
  {
    name: "Audio 3",
    score: 90,
    dateAdded: "2024-01-10",
    status: "Completed",
  },
  { name: "Audio 4", score: 60, dateAdded: "2024-01-15", status: "Pending" },
  {
    name: "Audio 1",
    score: 85,
    dateAdded: "2024-01-01",
    status: "Completed",
  },
  {
    name: "Audio 1",
    score: 85,
    dateAdded: "2024-01-01",
    status: "Completed",
  },
  {
    name: "Audio 1",
    score: 85,
    dateAdded: "2024-01-01",
    status: "Completed",
  },
];

export const newTableData = [
  { column1: "Hold Flow Marks", column2: "5.0" },
  { column1: "Audio Duration", column2: "2.8 mins " },
  {
    column1: "Detected Issue from List",
    column2: "Account Information Update",
  },
  {
    column1: "Issue Title System Generated",
    column2: "Ownership Change Confirmation",
  },
  { column1: "Long Holds > 30s", column2: "0" },
];

export const metrics = [
  { maxScore: 4 },
  { maxScore: 5 },
  { maxScore: 10 },
  { maxScore: 10 },
  { maxScore: 20 },
  { maxScore: 5 },
  { maxScore: 20 },
  { maxScore: 5 },
];
