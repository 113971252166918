import React from "react";
import { Box, Typography, Card, Grid } from "@mui/material";

const styles = {
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 800,
  textAlign: 'center',
};

const OurClientsComponent = () => {
  return (
    <Box sx={{ padding: 4, mb: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h2" sx={{ ...styles, fontWeight: 'bold' }}>
            Our Clients
          </Typography>
          <Typography variant="h5" sx={{ textAlign: 'center', marginBottom: 2 }}>
            We are proud to work with these amazing clients.
          </Typography>
        </Grid>

        {/* Card 2 */}
        <Grid item xs={12} sm={4}>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card 
            sx={{ 
              backgroundColor: '#101b23',
              height: '40vh', 
              color: '#fff', 
              borderRadius: '22px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center'
            }}
          >
            <img 
              src="/Nayatel.png" 
              alt="Client Logo 2" 
              style={{ width: '60%', height: 'auto' }} 
            />
          </Card>
          <div></div>
        </Grid>

        <Grid item xs={12} sm={4}>
        </Grid>

        {/* Card 3 */}
       
      </Grid>
    </Box>
  );
};

export default OurClientsComponent;
