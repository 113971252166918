import React, { useEffect, useState, useRef } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from '@mui/system';
import { Fade } from '@mui/material';

const ImageBox = styled(Box)(({ theme }) => ({
  backgroundImage: 'url(https://img.freepik.com/premium-photo/robot-with-blue-face-word-robot-it_821898-1081.jpg?w=360)', // Replace with your image URL
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: '10px',
  margin: 'auto',
  boxShadow: '0 0 10px rgba(0,0,0,0.2)',
  width: '100%', 
  height: '100%', 
  aspectRatio: '1 / 1', 
  [theme.breakpoints.up('md')]: {
    width: '40%', 
    height: '80%', 
    aspectRatio: 'unset', 
  },
}));

const AboutSectionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column', 
  alignItems: 'center',
  height: 'auto', 
  padding: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    height: '60vh', 
  },
}));

const AboutSectionComponent = () => {
  const [isVisible, setIsVisible] = useState(false);
  const aboutSectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.3 } // Adjust as needed
    );

    if (aboutSectionRef.current) {
      observer.observe(aboutSectionRef.current);
    }

    return () => {
      if (aboutSectionRef.current) {
        observer.unobserve(aboutSectionRef.current);
      }
    };
  }, []);

  return (
    <Box ref={aboutSectionRef}>
      <Fade in={isVisible} timeout={4000}>
        <AboutSectionContainer>
          <ImageBox />
          <Box sx={{ padding: 2, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', maxWidth: '600px' }}>
            <Typography variant="h3" sx={{ fontFamily: 'Poppins, sans-serif',fontWeight:800, marginBottom: 2, fontSize: { xs: '1.8rem', md: '2.5rem' } }}>
              What Tonesync AI Does?
            </Typography>
            <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif',fontWeight:400, lineHeight: 1.5 }}>
              ToneSync is an advanced AI-driven solution for automated Quality Assurance on customer-business audio calls. 
              It eliminates delays in QA for large call volumes, providing fast and accurate transcriptions, summaries, insights,
              analysis, and assigns scores to CSRs to ensure high-quality customer satisfaction.
            </Typography>
          </Box>
        </AboutSectionContainer>
      </Fade>
    </Box>
  );
};

export default AboutSectionComponent;
