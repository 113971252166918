// CustomModal.js
import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '8px',
  fontFamily: '"Poppins", sans-serif', // Apply font family to the modal
};

const buttonStyle = {
  width: '100%', // Full width
  textAlign: 'center', // Center text
};

const CustomModal = ({ open, handleClose, heading, message }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-title" variant="h6" component="h2" align="center">
          {heading}
        </Typography>
        <Typography id="modal-description" sx={{ mt: 2, textAlign: 'center', lineHeight: '1.5' }}>
          {message}
        </Typography>
        <Button 
          onClick={handleClose} 
          variant="contained" 
          sx={{ ...buttonStyle, mt: 3, backgroundColor: '#101b23', '&:hover': { backgroundColor: '#0e1a1f' } }} // Custom background color and hover effect
        >
          Contact to Admin
        </Button>
      </Box>
    </Modal>
  );
};

export default CustomModal;
