import React from "react";
import { Box } from "@mui/material";
import ViewDetailsContent from "../Components/ViewDetailsContent/ViewDetailsContent";
import Navbar from "../Components/NavBarComponent";
import { useLocation } from "react-router-dom";
const ViewDetailsPage = () => {
  const location = useLocation();
  const audioId = location.state?.audioId;
  return (
    <>
      <Navbar/>
      <Box
        sx={{
          backgroundColor: "#101b23",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ViewDetailsContent  audioId = {audioId} />
      </Box>
    </>
  );
};

export default ViewDetailsPage;
