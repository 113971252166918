import React from "react";
import { Box, Typography, Button, Grid, IconButton } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import GoogleMapReact from "google-map-react"; // Assuming you're using Google Maps, install google-map-react

// Custom styles for the footer
const styles = {
  footerContainer: {
    padding: 4,
    color: "white",
  },
  leftGrid: {
    textAlign: "left",
  },
  rightGrid: {
    textAlign: "center",
  },
  button: {
    marginTop: 2,
    backgroundColor: "#305269",
    color: "white",
    "&:hover": {
      backgroundColor: "#003a8f",
    },
  },
  icon: {
    marginRight: 1,
    verticalAlign: "middle",
  },
  mapContainer: {
    height: 200,
    width: "100%",
    marginTop: 2,
  },
};

const FooterComponent = () => {
  return (
    <Box sx={styles.footerContainer}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} sx={styles.leftGrid}>
          <Typography
            variant="h6"
            sx={{ fontFamily: "Poppins, sans-serif", fontWeight: 700, marginBottom: 2 }}
          >
            Proudly presented by Ayzenn Technologies. Make your mark!
          </Typography>
          <Box display="flex" alignItems="center" sx={{ marginBottom: 1 }}>
            <EmailIcon sx={styles.icon} />
            <Typography variant="body2">official@ayzenntechnologies.com</Typography>
          </Box>
          <Box display="flex" alignItems="center" sx={{ marginBottom: 1 }}>
            <PhoneIcon sx={styles.icon} />
            <Typography variant="body2">+92 3316670557</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <LocationOnIcon sx={styles.icon} />
            <Typography variant="body2">
              Plaza 13, Mini Commercial, Bahria Town Phase 7, Islamabad
            </Typography>
          </Box>
        </Grid>

        {/* Right section for the map and chat button */}
        <Grid item xs={12} md={6} sx={styles.rightGrid}>
          <Box sx={styles.mapContainer}>
            {/* Google Maps iframe (for simplicity) */}
            <iframe
  title="Ayzenn Technologies Location"
  width="100%"
  height="200"
  frameBorder="0"
  style={{ border: 0, borderRadius: 12 }}
  src="https://www.openstreetmap.org/export/embed.html?bbox=72.89367%2C33.66788%2C72.89767%2C33.67188&layer=mapnik&marker=33.66988%2C72.89567"
  allowFullScreen
></iframe>
          </Box>
          <Button variant="contained" sx={styles.button}>
            Chat with Us
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FooterComponent;
