import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { SnackbarContent } from '@mui/material';

export default function CustomSnackBar({ open, messageBackEnd, onClose }) {
  const [snackbarMessage, setSnackbarMessage] = React.useState(messageBackEnd);
  const [snackbarColor, setSnackbarColor] = React.useState('');
  const timerRef = React.useRef();
  React.useEffect(() => {
    setSnackbarMessage(messageBackEnd);
    if (messageBackEnd.includes('Network Error')) {
      setSnackbarColor('red');
    } else if (messageBackEnd.includes('This file has already been uploaded')) {
      setSnackbarColor('#FFCCCB');
    } else if (messageBackEnd) {
      setSnackbarColor('darkgoldenrod');
    }
    if (open) {
      timerRef.current = setTimeout(() => {
        handleClose();
      }, 3000);
    }
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [messageBackEnd, open]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarMessage('');
    if (onClose) {
      onClose();
    }
  };
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      onClose={handleClose}
    >
      <SnackbarContent
        style={{
          backgroundColor: snackbarColor,
          borderRadius: '8px',
          padding: '8px 16px',
          minWidth: '200px',
        }}
        message={snackbarMessage}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Snackbar>
  );
}
