export const parseSRT = (srt) => {
  const lines = srt.split("\n");
  const subtitles = [];
  let currentSubtitle = {};

  lines.forEach((line) => {
    const timeMatch = line.match(
      /(\d{2}):(\d{2}):(\d{2},\d{3}) --> (\d{2}):(\d{2}):(\d{2},\d{3})/
    );
    if (timeMatch) {
      if (currentSubtitle.text) {
        subtitles.push(currentSubtitle);
        currentSubtitle = {};
      }
      currentSubtitle.start = new Date(
        `1970-01-01T${timeMatch[1]}:${timeMatch[2]}:${timeMatch[3].replace(
          ",",
          "."
        )}Z`
      ).getTime();
      currentSubtitle.end = new Date(
        `1970-01-01T${timeMatch[4]}:${timeMatch[5]}:${timeMatch[6].replace(
          ",",
          "."
        )}Z`
      ).getTime();
    } else if (currentSubtitle.start !== undefined) {
      currentSubtitle.text = (currentSubtitle.text || "") + line + " ";
    }
  });

  if (currentSubtitle.text) {
    subtitles.push(currentSubtitle);
  }

  return subtitles;
};
