
import DescriptionIcon from '@mui/icons-material/Description';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SummarizeIcon from '@mui/icons-material/Summarize';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import BuildIcon from '@mui/icons-material/Build';

export const audioDummyData = {
    title: "Sample Audio",
    src: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3", 
  };

  export const transcriptionDummyData = `
    This is a sample transcription text that is quite long to demonstrate how it looks in the scrollable area.
    You can replace this text with actual transcription data.
  `;

  export const menuItems = [
    { text: "English Transcript", icon: <DescriptionIcon /> },
    { text: "Urdu Transcript", icon: <DescriptionIcon /> },
    { text: "Detailed Analysis", icon: <AssessmentIcon /> },
    { text: "Short Summary", icon: <SummarizeIcon /> },
    { text: "Detailed Summary", icon: <SummarizeIcon /> },
    { text: "Hold Flow", icon: <PauseCircleOutlineIcon /> },
    { text: "Troubleshoot Steps", icon: <BuildIcon /> },


    
  ];
