import React, { useState, useContext } from "react";
import {
  Toolbar,
  Typography,
  InputBase,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import StarIcon from "@mui/icons-material/Star";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import CallIcon from "@mui/icons-material/Call";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../contexts/GlobalContext";
import { LoginOutlined } from "@mui/icons-material";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#284457",
  "&:hover": {
    backgroundColor: "#284457",
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { token } = useContext(GlobalContext);
  //console.log("as" + token);

  const navigate = useNavigate();

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button onClick={() => navigate("/dashboard")}>
          <HomeIcon sx={{ mr: 1 }} />
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button onClick={() => navigate("/exploreaudio")}>
          <CallIcon sx={{ mr: 1 }} />
          <ListItemText primary="Explore Calls" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box
      sx={{
        backgroundColor: "#101b23",
        position: "fixed",
        width: "100%",
        zIndex: 1100,
        color: "white",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingX: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
          <div
            onClick={() => navigate("/")}
            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          >
            <StarIcon sx={{ mr: 1 }} />
            <Typography
              variant="h6"
              noWrap
              sx={{ fontFamily: "Poppins, sans-serif", fontWeight: 800 }}
            >
              ToneSync <span style={{ color: "yellow" }}>AI</span>
            </Typography>
          </div>
          <Box sx={{ display: { xs: "none", md: "flex" }, ml: 4 }}>
            <div
              onClick={() => navigate("/dashboard")}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <HomeIcon sx={{ mr: 0.7 }} />
              <Typography
                variant="button"
                sx={{ fontFamily: "Poppins, sans-serif", fontWeight: 800 }}
              >
                Home
              </Typography>
            </div>
            <div
              onClick={() => navigate("/exploreaudio")}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                marginLeft: 10,
              }}
            >
              {token ? (
                <>
                  <CallIcon sx={{ mr: 0.7 }} />
                  <Typography
                    variant="button"
                    sx={{ fontFamily: "Poppins, sans-serif", fontWeight: 800 }}
                  >
                    Explore Calls
                  </Typography>
                </>
              ) : null}
            </div>
          </Box>
        </Box>

        <Box sx={{ display: { xs: "none", md: "flex" }, mr: 2 }}>
          <Search sx={{ backgroundColor: "#284457", borderRadius: "12px" }}>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
        </Box>
        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        </Box>
      </Toolbar>

      {/* Drawer for Mobile Menu */}
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </Box>
  );
};

export default Navbar;
