import React from 'react';
import { Button, Typography, Box } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import PlayArrowIcon from '@mui/icons-material/PlayArrow'; // Importing icon
import { useNavigate } from 'react-router-dom';

// Animation for text
const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const HeroSection = styled(Box)(({ theme }) => ({
  height: '100vh',
  backgroundImage: 'url(/dr4.jpg)', 
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  fontFamily: "'Kalam', sans-serif",
  padding: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    height: '60vh',
  },
  [theme.breakpoints.down('sm')]: {
    height: '50vh',
  },
}));

const HeroButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: '#004C99', // Darker bluish color
  color: '#ffffff',
  '&:hover': {
    backgroundColor: '#003366', // Even darker shade on hover
  },
}));

const HeroHeading = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins, sans-serif',
  fontWeight:800,
  animation: `${fadeIn} 1s ease-in-out`,
  color: '#ffffff',
  [theme.breakpoints.up('md')]: {
    fontSize: '4rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '3rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.5rem',
  },
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 500,
  animation: `${fadeIn} 1.5s ease-in-out`,
  color: '#ffffff',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.2rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
}));

const Hero = () => {
  const navigate = useNavigate();
  return (
    <HeroSection>
      <HeroHeading
        variant="h1"
        sx={{
          color: '#ffffff',
        }}
      >
        Tonesync <span style={{ color: '#FFD700' }}>AI</span> {/* 'AI' in yellow */}
      </HeroHeading>
      <SubHeading variant="h5">
        Elevate Your Business Quality Assurance!
      </SubHeading>
      <HeroButton variant="contained" startIcon={<PlayArrowIcon />}  onClick={() => navigate('/login')} > 
        Start
      </HeroButton>
    </HeroSection>
  );
};

export default Hero;
