import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, List, ListItem, ListItemText } from '@mui/material';

const AlertDialog = ({ open, setOpen, title, message }) => {
    const handleClose = () => {
        setOpen(false);
    };

    const isUploadMessage = message.includes("Successfully uploaded files:");

    let successFiles = [];
    let remainingFiles = [];

    if (isUploadMessage) {
        const [successMessage, remainingMessage] = message.split("Failed to upload(Dublicates/Not Allowed):");
        successFiles = successMessage.replace("Successfully uploaded files: ", "").trim().split(', ').filter(file => file);
        remainingFiles = remainingMessage ? remainingMessage.trim().split(', ').filter(file => file) : [];
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"  
            fullWidth    
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <div
                    style={{
                        maxHeight: '300px', 
                        overflowY: 'auto',  
                        padding: '10px',    
                    }}
                >
                    {isUploadMessage ? (
                        <>
                            {/* Display success files in green */}
                            {successFiles.length > 0 && (
                                <>
                                    <Typography variant="subtitle1" style={{ color: 'green', fontWeight: 'bold' }}>
                                        Successfully submitted:  {successFiles?.length}  Files for processing
                                    </Typography>
                                    <List>
                                        {successFiles.map((file, index) => (
                                            <ListItem key={index}>
                                                <ListItemText primary={file} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </>
                            )}
                            
                            {/* Display remaining files in red */}
                            {remainingFiles.length > 0 && (
                                <>
                                    <Typography variant="subtitle1" style={{ color: 'red', fontWeight: 'bold' }}>
                                        Files Not Uploaded: ( {remainingFiles?.length}  )
                                    </Typography>
                                    <List>
                                        {remainingFiles.map((file, index) => (
                                            <ListItem key={index}>
                                                <ListItemText primary={file} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </>
                            )}
                        </>
                    ) : (
                        // Normal message display
                        <Typography variant="body1">{message}</Typography>
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AlertDialog;
