// src/contexts/GlobalContext.js
import React, { createContext, useState } from "react";

// Create context
export const GlobalContext = createContext();

// Create provider
export const GlobalProvider = ({ children }) => {
  const a = localStorage.getItem("loginToken");

  const [token, setToken] = useState(a ? a : null); // Example state for theme

  return (
    <GlobalContext.Provider value={{ token, setToken }}>
      {children}
    </GlobalContext.Provider>
  );
};
