import React from "react";
import ExploreAudioContent from "../Components/ExploreAudio/ExploreAudioConetent";
import { Box } from "@mui/material";
import Navbar from "../Components/NavBarComponent";
const ExploreAudio = () => {
  return (
    <>
    <Navbar />
    <Box 
      sx={{ 
        backgroundColor: "#101b23", 
        minHeight: "100vh", 
        padding: 1,
        paddingTop:10 
      }}
    >
      <ExploreAudioContent />
    </Box>
  </>
  );
};

export default ExploreAudio;
