// src/components/PrivateRoute.js
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { GlobalContext } from "./GlobalContext";

const PrivateRoute = ({ element }) => {
  const { token } = useContext(GlobalContext);

  return token ? element : <Navigate to="/login" replace />;
};

export default PrivateRoute;
