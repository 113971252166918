import React from "react";
import { Box, Typography, Grid, Card, CardContent } from "@mui/material";
import { Translate, Assessment, Score, Report } from "@mui/icons-material"; 
import { styled } from '@mui/system';

const fadeIn = `
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
const StyledCard = styled(Card)(({ theme }) => ({
  ...fadeIn,
  backgroundColor: '#101b23',
  
  borderColor:'gray',
  display: 'flex',
 
  color: 'white',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '16px',
  padding: theme.spacing(2),
  flex: '1 1 auto',
  height: '50vh',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  transition: '0.3s',
  animation: 'fadeIn 1s ease-in forwards', // Apply the fade-in animation
  '&:hover': {
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
  },
}));
const KeyFeaturesComponent = () => {
  return (
    <Box sx={{ padding: 2, mb: 4, display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h3" sx={{ color: '#fff', textAlign: 'center', marginBottom: 4, fontFamily: 'Poppins, sans-serif', fontWeight:800 }}>
        Key Features
      </Typography>
      <Typography variant="body1" sx={{ color: '#fff', textAlign: 'center', marginBottom: 4,fontFamily: 'Poppins, sans-serif' ,fontWeight:800}}>
        Discover the key features that set us apart.
      </Typography>
      <Grid container spacing={4} justifyContent="center" alignItems="stretch" sx={{ flex: 1 }}> {/* Flex-grow for grid */}
        {/* Card 1 */}
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <Translate sx={{ fontSize: '100px', color: '#4caf50' }} /> {/* Icon */}
            <CardContent>
              <Typography variant="h5" sx={{ fontFamily: 'Poppins, sans-serif' ,fontWeight:800, textAlign: 'center',  }}>
                Accurate Transcriptions
              </Typography>
              <ul>
                <li><Typography variant="body2">Including Urdu and English.</Typography></li>
                <li><Typography variant="body2">Supports 50+ Languages.</Typography></li>
              </ul>
            </CardContent>
          </StyledCard>
        </Grid>
        {/* Card 2 */}
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <Assessment sx={{ fontSize: '100px', color: '#2196f3' }} /> {/* Icon */}
            <CardContent>
              <Typography variant="h5" sx={{ fontFamily: 'Poppins, sans-serif' ,fontWeight:800, textAlign: 'center',  }}>
                In-depth Analysis
              </Typography>
              <ul>
                <li><Typography variant="body2">Automated AI-based analysis on every call.</Typography></li>
                <li><Typography variant="body2">Considers more than 10 metrics to evaluate each call.</Typography></li>
                <li><Typography variant="body2">Customization option for evaluation metrics.</Typography></li>
              </ul>
            </CardContent>
          </StyledCard>
        </Grid>
        {/* Card 3 */}
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <Score sx={{ fontSize: '100px', color: '#f44336' }} /> {/* Icon */}
            <CardContent>
              <Typography variant="h5" sx={{ fontFamily: 'Poppins, sans-serif' ,fontWeight:800, textAlign: 'center',  }}>
                Automated Scoring
              </Typography>
              <ul>
                <li><Typography variant="body2">Assigns score to representatives as automated QA.</Typography></li>
                <li><Typography variant="body2">Customization available for custom evaluation metrics.</Typography></li>
              </ul>
            </CardContent>
          </StyledCard>
        </Grid>
        {/* Card 4 */}
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <Report sx={{ fontSize: '100px', color: '#ff9800' }} /> {/* Icon */}
            <CardContent>
              <Typography variant="h5" sx={{ fontFamily: 'Poppins, sans-serif' ,fontWeight:800, textAlign: 'center',  }}>
                Issue Detection & Summaries
              </Typography>
              <ul>
                <li><Typography variant="body2">Generates summary of calls covering key points.</Typography></li>
                <li><Typography variant="body2">Highlights topics/issues discussed during the call.</Typography></li>
                <li><Typography variant="body2">Highlights issues raised by the customer.</Typography></li>
              </ul>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default KeyFeaturesComponent;
