import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Switch,
  FormControlLabel,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { GlobalContext } from "../../contexts/GlobalContext";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link } from "react-router-dom";
import { ip } from "../../config";
import AlertDialog from "../MessageBox/message";
const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { setToken } = useContext(GlobalContext);
  const navigate = useNavigate();

  const [pass, setPass] = useState("");
  const [mail, setMail] = useState("");
  const [show, setShow] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleLogin = async () => {
    try {
      if (!mail || !pass) {
        setShow(true);
        return;
      }
      // Send the login request
      const response = await axios.post(ip + "/login", {
        username: mail,
        password: pass,
      });

      if (response.data.token) {
        const token = response.data.token;

        // Store token in localStorage (or AsyncStorage if React Native)
        localStorage.setItem("loginToken", token);

        // Update global loginToken state
        setToken(token);

        // Redirect to dashboard
        navigate("/dashboard");
      } else {
        setShow(true);
        // console.error("Login failed");
      }
    } catch (error) {
      setShow(true);

      // console.error("Login error:", error);
    }
  };

  return (
    <Box
      sx={{ width: "100%", maxWidth: 400, mx: "auto", textAlign: "left", p: 3 }}
    >
      <AlertDialog
        message={"Invalid Credentials! Please try again! "}
        open={show}
        setOpen={setShow}
        title={"Authentication Failed"}
      ></AlertDialog>
      <Box
        sx={{
          mb: 2,
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          src="https://cdn-icons-png.flaticon.com/512/2021/2021646.png"
          alt="Logo"
          style={{
            borderRadius: "50%",
            width: "auto",
            height: "auto",
            maxWidth: "50px",
            maxHeight: "50px",
          }}
        />

        <Typography variant="h6" sx={{ fontWeight: 700, ml: 1 }}>
          ToneSync AI
        </Typography>
      </Box>
      <Typography variant="h5" sx={{ mb: 2, fontWeight: "bold" }}>
        Nice to see you again
      </Typography>
      <Box component="form" sx={{ mt: 3 }}>
        <Typography
          variant="body1"
          sx={{ textAlign: "left", mb: 1, fontWeight: "bold" }}
        >
          Login
        </Typography>
        <TextField
          value={mail}
          onChange={(e) => setMail(e.target.value)}
          label="Email "
          type="email"
          variant="outlined"
          fullWidth
          margin="normal"
          required
          InputProps={{
            sx: {
              borderRadius: "12px",
              backgroundColor: "#FFF7FD",
            },
          }}
        />
        <Typography
          variant="body1"
          sx={{ textAlign: "left", mb: 1, fontWeight: "bold" }}
        >
          Password
        </Typography>
        <TextField
          value={pass}
          onChange={(e) => setPass(e.target.value)}
          label="Enter password"
          type={showPassword ? "text" : "password"}
          variant="outlined"
          fullWidth
          margin="normal"
          required
          InputProps={{
            sx: {
              borderRadius: "12px",
              backgroundColor: "#FFF7FD",
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 1,
          }}
        >
          <FormControlLabel
            control={<Switch color="primary" />}
            label="Remember me"
            sx={{ ml: 0 }}
          />
        </Box>

        <Button
          onClick={handleLogin}
          fullWidth
          variant="contained"
          sx={{
            mt: 1,
            mb: 2,
            height: 50,
            borderRadius: "12px",
            backgroundColor: "#1a73e8",
            fontSize: "16px",
            textTransform: "none",
          }}
        >
          Sign in
        </Button>
      </Box>
    </Box>
  );
};

export default LoginForm;
