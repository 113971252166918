import React from "react";
import { Box, Typography, Grid, Card, CardContent, CardActions, Button } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Icon for accordion
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

const FrequentlyAskedComponent = () => {
  return (
    <Box sx={{ padding: 4, mb: 4 }}>
      <Typography 
        variant="h3" 
        sx={{ 
          textAlign: 'center', 
          marginBottom: 2,
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 'bold' 
        }}
      >
        Frequently Asked Questions
      </Typography>
      <Typography 
        variant="body1" 
        sx={{ 
          textAlign: 'center', 
          marginBottom: 4 
        }}
      >
        Find answers to the most common questions.
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        {[ 
          {
            question: "Can ToneSync be integrated with existing CMS/System?",
            answer: "Yes, ToneSync provides seamless integration options with existing CMS."
          },
          {
            question: "Can ToneSync be deployed on Premises?",
            answer: "Yes, ToneSync allows companies to deploy the system on their premises if they prefer."
          },
          {
            question: "Is ToneSync accurate and secure?",
            answer: "Yes, ToneSync has an accuracy of around 80% under desirable circumstances (good audio quality) & it's 100% secure."
          }
        ].map((faq, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card 
              sx={{ 
                marginBottom: 2, 
                borderRadius: 2, 
                boxShadow: 3,
                backgroundColor: '#0D3858', 
                color: 'white', 
                display: 'flex', 
                flexDirection: 'column',
                minHeight: '130px', // Initial fixed height
                transition: 'min-height 0.3s ease', 
              }}
            >
              <Accordion 
          
                sx={{ 
                  backgroundColor: '#182934', 
                  color: 'white', 
                  flexGrow: 1 
                }}
              >
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon  sx={{ color: 'white' }} />} 
                  aria-controls={`panel${index + 1}a-content`} 
                  id={`panel${index + 1}a-header`}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h6">{faq.question}</Typography>
                  </CardContent>
                </AccordionSummary>
                <AccordionDetails>
                  <CardContent>
                    <Typography>{faq.answer}</Typography>
                  </CardContent>
                </AccordionDetails>
              </Accordion>
              
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FrequentlyAskedComponent;
