import React from "react";
import { Grid } from "@mui/material";
import CoverImage from "../Components/LoginForm/CoverImage";
import LoginForm from "../Components/LoginForm/LoginForm";
const LoginPage = () => {
  return (
    <Grid container sx={{ height: "100vh" }}>
      <Grid
        item
        xs={false}
        md={9}
        sx={{
          display: { xs: "none", md: "block" },
          backgroundColor: "#f5f5f5",
        }}
      >
        <CoverImage />
      </Grid>
      <Grid
        item
        xs={12}
        md={3}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <LoginForm />
      </Grid>
    </Grid>
  );
};
export default LoginPage;
