import React, { useState } from "react";
import { Box, Typography, Grid, TextField, Button } from "@mui/material";
const styles = {
  fontFamily: 'Poppins, sans-serif',
  textAlign: 'center',
  fontWeight: 700
};
const ContactSectionComponent = () => {
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});
  return (
    <Box 
      sx={{ 
        padding: { xs: 2, md: 4 }, 
        minHeight: '78vh', 
        display: 'flex', 
        alignItems: 'center',
       
      }}
    >
      <Grid container spacing={4}>
        <Grid 
          item 
          xs={12} 
          md={6} 
          sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center',
            textAlign: { xs: 'center', md: 'left' }, 
          }}
        >
          <Typography 
            variant="h4" 
            sx={{ ...styles, marginBottom: 2, color: 'white' }}
          >
            For any query
          </Typography>
          <Typography 
            variant="h6" 
            sx={{ ...styles, color: 'white' }}
          >
            Drop a message, our team will reach out to you ASAP!
          </Typography>
        </Grid>

        <Grid 
          item 
          xs={12} 
          md={6} 
        >
          <Box 
            sx={{ 
              padding: { xs: 2, md: 3 }, // Responsive padding for mobile and desktop
              backgroundColor: '#182934', 
              borderRadius: 2, 
              boxShadow: 2,
             
              maxWidth: '500px', // Maximum width to keep form compact on larger screens
              margin: '0 auto', // Center on mobile
            }}
          >
            <Typography 
              variant="h5" 
              sx={{ ...styles, marginBottom: 2, color: 'white' }}
            >
              Contact Us
            </Typography>

            {/* Contact Form */}
            <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                label="Name"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                error={!!errors.name}
                helperText={errors.name}
                required
                InputProps={{
                  style: { color: 'white' },
                }}
                InputLabelProps={{
                  style: { color: 'white' }, 
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: 'white' },
                    '&:hover fieldset': { borderColor: 'white' },
                    '&.Mui-focused fieldset': { borderColor: 'white' },
                  },
                }}
              />
              <TextField
                label="Phone Number"
                variant="outlined"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber}
                required
                InputProps={{
                  style: { color: 'white' }, 
                }}
                InputLabelProps={{
                  style: { color: 'white' }, 
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: 'white' },
                    '&:hover fieldset': { borderColor: 'white' },
                    '&.Mui-focused fieldset': { borderColor: 'white' },
                  },
                }}
              />
              <TextField
                label="Message"
                variant="outlined"
                multiline
                rows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                error={!!errors.message}
                helperText={errors.message}
                required
                InputProps={{
                  style: { color: 'white' }, 
                }}
                InputLabelProps={{
                  style: { color: 'white' }, 
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: 'white' },
                    '&:hover fieldset': { borderColor: 'white' },
                    '&.Mui-focused fieldset': { borderColor: 'white' },
                  },
                }}
              />
              <Button type="submit" variant="contained" sx={{ backgroundColor: '#305269', color: '#fff' }}>
                Submit
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactSectionComponent;
