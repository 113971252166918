import React from "react";
import { Box, Typography } from "@mui/material";
const styles = {
  fontFamily: 'Poppins, sans-serif',
  textAlign: 'center',
};
const ProductVideoSectionComponent = () => {
  return (
    <Box 
      sx={{ 
        padding: 4, 
        minHeight: '100vh', 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center',
        gap: 2,
      }}
    >
      <Typography variant="h3" sx={{ ...styles, marginBottom: 2, fontWeight: 'bold' }}>
        Product Video
      </Typography>
      <Typography variant="body1" sx={{ ...styles, marginBottom: 4 }}>
        Watch our product video to learn more.
      </Typography>
      <Box
         component="iframe"
        src="https://www.youtube.com/embed/hZXwhqONks4" 
        title="Product Video"
        sx={{ 
          width: { xs: '100%', sm: '90%', md: '80%' }, 
          height: { xs: '40vh', sm: '50vh', md: '70vh' }, 
          border: 'none',
          borderRadius: '10px', 
          boxShadow: 3, 
        }}
      />
    </Box>
  );
};

export default ProductVideoSectionComponent;
