import React from "react";
import { Box } from "@mui/material";
import HeroSectionComponent from "./HeroSection";
import AboutSectionComponent from "./AboutUsSection";
import WhyUsSectionComponent from "./WhyUsSection";
import KeyFeaturesComponent from "./KeyFeaturesSection";
import FrequentlyAskedComponent from "./FrequentlyAskedQuestionsSection";
import OurClientsComponent from "./OurClientsSection";
import ProductVideoSectionComponent from "./ProductVideoSection";
import ContactSectionComponent from "./ContactUs";
import FooterComponent from "./FooterSection";
const LandingPageContent = () => {
  return (
    <Box 
      sx={{ 
        backgroundColor: "#101b23", 
        color: "white"
      }}
    >
      <HeroSectionComponent />
      <AboutSectionComponent />
      <WhyUsSectionComponent />
      <KeyFeaturesComponent />
      <FrequentlyAskedComponent />
      <OurClientsComponent />
      <ProductVideoSectionComponent />
      <ContactSectionComponent />
      <FooterComponent />
    </Box>
  );
};

export default LandingPageContent;
