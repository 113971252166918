// src/components/Login/CoverImage.js

import React from "react";
import { Box } from "@mui/material";

const CoverImage = () => {
  return (
    <Box
      sx={{
        backgroundImage:
          "url(https://raw.githubusercontent.com/sudo-tahirsaeed/tempImages/refs/heads/main/cover.png)", // Replace with your own image URL
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
        height: "100%",
      }}
    />
  );
};

export default CoverImage;
