// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { GlobalProvider } from "./contexts/GlobalContext";
import LoginPage from "./Pages/LoginPage";
import Dashboard from "./Pages/MainDashBoard";
import ExploreAudio from "./Pages/ExploreAudio";
import ViewDetailsPage from "./Pages/ViewDetailPage";
import LandingPage from "./Pages/LandingPage";
import PrivateRoute from "./contexts/PrivateRoute";
import Test from "./test";
function App() {
  //Uncomment after checking test paste new token in test.js and check test page first
  return (
    
    <GlobalProvider>
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
        
          {/* Protected Routes */}
          <Route
            path="/viewdetails"
            element={<PrivateRoute element={<ViewDetailsPage />} />}
          />
          <Route
            path="/dashboard"
            element={<PrivateRoute element={<Dashboard />} />}
          />
          <Route
            path="/exploreaudio"
            element={<PrivateRoute element={<ExploreAudio />} />}
          />
        </Routes>
      </Router>
    </GlobalProvider>
  );
}

export default App;
